// eslint-disable-next-line no-redeclare,no-unused-vars
function updateView(param, param2) {
  var view = param.view;

  // table
  if (
    param2 &&
    param.type == param2.type &&
    param.objectSub == param2.objectSub &&
    view.table &&
    view.table.loadData
  ) {
    view.table.loadData();
  }

  // update full view
  else if (param2 && param2.updateAll) {
    delete param.view;
    param.updating = true;
    ajax(param);
  }

  // update main area
  else {
    if (view.nav) view.nav.empty();
    if (view.sidebar) view.sidebar.empty();
    if (view.buttonsLeft) view.buttonsLeft.empty();
    if (view.detail && view.buttonsRight)
      view.buttonsRight.children(':not(.btn-default)').remove();
    if (view.detail && !param.bodyCard) view.main.children('.card').remove();
    if (view.formBuilt) view.formBuilt = false;
    view.body.empty();
    view.find('.select-dropdown-container').remove();
    if (param2) {
      delete param.ident;
      param = Object.assign(param, param2);
      view.objectSub = param.objectSub;
      if (view.detail)
        view.setTitle(
          param.title,
          null,
          null,
          getParamSettings(param, 'label')
        );
      else view.setTitle(getParamSettings(param, 'label'));
    }
    var pm = Object.assign({updating: true, send: {}, view: view}, param);
    if (view.table && pm.send && pm.send.where)
      pm.send.where = view.table.filter;
    if (param2 && param2.update) pm.send.update = true;
    if (param2 && param2.formFix) pm.formFix = true;
    if (param.send && param.send.func && !param.keepFunc)
      delete param.send.func;
    if (param.send && param.send.ids) delete param.send.ids;
    ajax(pm);
  }
}
